import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// import { AppBar, Toolbar, Box } from "@mui/material";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { useSnackbar } from "notistack";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Homepage() {
  const [username, setUsername] = useState("");
  const [campaignInfo, setCampaignInfo] = useState(null);
  const routerNavigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_BASE_URL + "/crowdsourcer/getInfo"
        );
        setUsername(res.data.username);
        setCampaignInfo(res.data);
      } catch (err) {
        enqueueSnackbar("Unable to get MW campaign info", { variant: "error" });
        console.error(err);
      }
    };
    func();
  }, []);

  const logout = async () => {
    try {
      await axios.post(process.env.REACT_APP_BASE_URL + "/crowdsourcer/logout");
      routerNavigate("/", { replace: true });
    } catch (err) {
      enqueueSnackbar("Failed to logout", { variant: "error" });
      console.error(err);
    }
  };

  //Go back to campaigns list
  const campaigns = async() => {
    routerNavigate("/crowdsourcer");
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static'>
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              News
            </Typography> */}
            <Typography>Hello, {username}!</Typography>
            <Button
              onClick={logout}
              variant='contained'
              color='secondary'
              sx={{ ml: "2em" }}
            >
              logout
            </Button>
            <Button
              onClick={campaigns}
              variant='contained'
              color='secondary'
              sx={{ ml: "2em" }}
            >
              campaigns
            </Button>
          </Toolbar>
        </AppBar>
      </Box>

      <Outlet context={[campaignInfo, setCampaignInfo]} />
    </>
  );
}

export default Homepage;
