import axios from "axios";
import React, { useState, useEffect, useContext, createContext } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DataComponent } from "./DataView";
import { sensorNameArray } from "../utils/functions";
import * as dfns from "date-fns";

import { CSVLink } from "react-csv";

import {
  Container,
  Backdrop,
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Badge,
  Button,
  Box,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Select,
  MenuItem,
  TextField,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CancelIcon from "@mui/icons-material/Cancel";

const CampaignContext = createContext(null);

function Data() {
  const { campaignId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("0");

  const [completed, setCompleted] = useState([]);
  const [review, setReview] = useState([]);
  const [failed, setFailed] = useState([]);

  const [campaign, setCampaign] = useState(null);

  const [openDataDialog, setOpenDataDialog] = useState(false);
  const [dialogJob, setDialogJob] = useState(null);

  const handleShowData = (job) => {
    setDialogJob(job);
    setOpenDataDialog(true);
  };

  const handleChange = (event, newVal) => {
    setValue(newVal);
  };

  useEffect(() => {
    setLoading(true);

    getJobs();
  }, []);

  const getJobs = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_BASE_URL + "/crowdsourcer/getJobs",
        { params: { campaignId: campaignId } }
      );
      // console.log(res.data);
      setCampaign(res.data.campaign);
      const tmpCompl = [];
      const tmpRev = [];
      const tmpFail = [];

      res.data.jobs.map((job) => {
        switch (job.status) {
          case "RATED":
            tmpCompl.push(job);
            break;

          case "READY_TO_RATE":
            tmpRev.push(job);
            break;

          case "FAILED":
            tmpFail.push(job);
            break;
        }
      });

      setCompleted(tmpCompl);
      setReview(tmpRev);
      setFailed(tmpFail);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to get jobs: " + err.respose.data.message, {
        variant: "error",
      });
    }
    setLoading(false);
  };
  return (
    <>
      <CampaignContext.Provider value={{ campaign, getJobs }}>
        <DataDialog
          open={openDataDialog}
          onClose={() => {
            setOpenDataDialog(false);
          }}
          job={dialogJob}
          campaign={campaign}
          enqueueSnackbar={enqueueSnackbar}
        />
        <Container maxWidth='100%' sx={{ padding: "1em" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
          <Paper sx={{ padding: "1em", minHeight: "100vh" }}>
            {campaign && (
              <>
                <Typography variant='h5'>Campaign: {campaign.title}</Typography>
                <Typography>{campaign.description}</Typography>
                <Typography variant='caption'>
                  Sensors:{" "}
                  {campaign.sensors.map((v, i) => {
                    return <span key={i}>{v.name} {i === (campaign.sensors.length - 1) ? '': ','} </span>;
                  })}
                </Typography>
                <br />
                {campaign.question && (
                  <>
                    <Typography variant='caption'>
                      Question: {campaign.question}
                    </Typography>
                    <br />
                  </>
                )}
                <Typography variant='caption'>ID: {campaign._id}</Typography>
              </>
            )}
            <Tabs value={value} onChange={handleChange} variant='fullWidth'>
              <Tab
                icon={
                  <Badge badgeContent={completed.length} color='error'>
                    <CheckCircleIcon />
                  </Badge>
                }
                iconPosition='start'
                label='completed'
                value='0'
                onClick={() => {
                  // console.log(completed);
                }}
              />
              <Tab
                icon={
                  <Badge badgeContent={review.length} color='error'>
                    <RateReviewIcon />
                  </Badge>
                }
                iconPosition='start'
                label='review'
                value='1'
                onClick={() => {
                  // console.log(review);
                }}
              />
              <Tab
                icon={
                  <Badge badgeContent={failed.length} color='error'>
                    <CancelIcon />
                  </Badge>
                }
                iconPosition='start'
                label='failed'
                value='2'
                onClick={() => {
                  // console.log(failed);
                }}
              />
            </Tabs>
            <ReviewTab
              index={0}
              value={value}
              jobs={completed}
              campaign={campaign}
              handleShowData={handleShowData}
            />
            <ReviewTab
              index={1}
              value={value}
              jobs={review}
              campaign={campaign}
              handleShowData={handleShowData}
            />
            <ReviewTab
              index={2}
              value={value}
              jobs={failed}
              campaign={campaign}
              handleShowData={handleShowData}
            />
          </Paper>
        </Container>
      </CampaignContext.Provider>
    </>
  );
}

const CompleteTab = ({ value, index, jobs }) => {
  useEffect(() => {
    // console.log(jobs);
  }, []);

  return <>{value == index && <Container>in complete</Container>}</>;
};

const ReviewTab = ({ value, index, jobs, handleShowData }) => {
  useEffect(() => {
    // console.log(jobs);
  }, []);

  return (
    <>
      {value == index && (
        <Container maxWidth={"xl"}>
          {jobs.map((job, i) => {
            return (
              <WorkRecord job={job} i={i} handleShowData={handleShowData} />
            );
          })}
        </Container>
      )}
    </>
  );
};

const FailTab = ({ value, index, jobs }) => {
  useEffect(() => {
    // console.log(jobs);
  }, []);

  return <>{value == index && <Container>in fail</Container>}</>;
};

const WorkRecord = ({ job, i, handleShowData }) => {
  const [openRate, setOpenRate] = useState(false);
  return (
    <>
      <RateDialog job={job} open={openRate} setOpen={setOpenRate} />
      <Paper elevation={10} key={i}>
        <Box
          sx={{
            mt: "0.5em",
            pt: "0.5em",
            px: "0.5em",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Worker ID: {job.workerIDMW}</Typography>
          {job.status == "FAILED" && (
            <Typography noWrap={true} maxWidth='20%'>
              Fail Reason: {job.failReason}
            </Typography>
          )}
          <Box>
            <Button
              onClick={() => {
                handleShowData(job);
              }}
              variant='contained'
              color='success'
            >
              show data
            </Button>
            {job.status === "READY_TO_RATE" && (
              <Button
                onClick={() => {
                  setOpenRate(true);
                }}
                sx={{ ml: "0.5em" }}
                variant='contained'
                color='warning'
              >
                rate
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ pb: "0.5em", px: "0.5em" }}>
          {job.status === "READY_TO_RATE" && (
            <Typography>
              {" "}
              Rate before:{" "}
              {dfns.add(new Date(job.createdAt), { days: 7 }).toLocaleString()}
            </Typography>
          )}
        </Box>
      </Paper>
    </>
  );
};

const DataDialog = (props) => {
  const { job, open, onClose, campaign } = props;
  const [loading, setLoading] = useState(true);
  const [sensorData, setSensorData] = useState(null);
  const [missing, setMissing] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (job) {
      getSensorData();
    }
  }, [open]);

  const getSensorData = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_BASE_URL + "/crowdsourcer/getSensorData",
        { params: { jobId: job._id } }
      );
      const sortedData = res.data.sort(
        (a, b) =>
          sensorNameArray.indexOf(a.name) - sensorNameArray.indexOf(b.name)
      );
      setSensorData(sortedData);

      const missingTmp = campaign.sensors.filter((s) => {
        // console.log(s.name + " :" + sortedData.some((d) => d.name == s.name));
        return sortedData.some((d) => d.name == s.name) === false;
      });
      setMissing(missingTmp);
      setLoading(false);
    } catch (err) {
      console.error(err);
      props.enqueueSnackbar("Failed to get sensor data of the job", {
        variant: "error",
      });
    }
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        onClose();
        setLoading(true);
      }}
    >
      {loading ? (
        <CircularProgress sx={{ m: "5em" }} />
      ) : (
        <>
          {job && sensorData && missing && (
            <DialogContent>
              <Typography>Job Id: {job._id}</Typography>
              <Typography>Worker ID: {job.workerIDMW}</Typography>
              <Typography>Status: {job.status}</Typography>
              <Typography>Answer: {job.answer}</Typography>
              {missing.length > 0 && (
                <Typography>
                  Missing:{" "}
                  {missing.map((v, i) => {
                    return (
                      <Typography color='red' variant='caption' key={i}>
                        {v.name},{" "}
                      </Typography>
                    );
                  })}
                </Typography>
              )}
              {job.status == "FAILED" && (
                <Typography>Fail reason: {job.failReason}</Typography>
              )}
              {sensorData.map((d, i) => {
                return (
                  <SensorReading
                    {...d}
                    key={i}
                    sensors={campaign.sensors}
                    jobId={job._id}
                  />
                );
              })}
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  );
};

const SensorReading = (props) => {
  const [sensorReqs, setSensorReqs] = useState(null);

  const commonHeaders = [
    { label: "timestamp", key: "timestamp" },
    { label: "latitude", key: "location.lat" },
    { label: "longitude", key: "location.lng" },
  ];

  const headers3d = [
    ...commonHeaders,
    { label: "x", key: "payload.x" },
    { label: "y", key: "payload.y" },
    { label: "z", key: "payload.z" },
  ];

  const headersQuat = [
    ...commonHeaders,
    { label: "quaternion 1", key: "payload.quaternion[0]" },
    { label: "quaternion 2", key: "payload.quaternion[1]" },
    { label: "quaternion 3", key: "payload.quaternion[2]" },
    { label: "quaternion 4", key: "payload.quaternion[3]" },
  ];

  const headersLight = [...commonHeaders, { label: "lux", key: "payload.lux" }];

  const getDownload = (name) => {
    switch (name) {
      case "Accelerometer":
      case "Gyroscope":
      case "Magnetometer":
      case "GravitySensor":
      case "LinearAccelerationSensor":
        return (
          <CSVLink
            filename={`${name}_${props.jobId}`}
            data={props.data}
            headers={headers3d}
          >
            <Button variant='contained' color='success' sx={{ my: "1em" }}>
              Download Data
            </Button>
          </CSVLink>
        );

      case "RelativeOrientationSensor":
      case "AbsoluteOrientationSensor":
        return (
          <CSVLink
            filename={`${name}_${props.jobId}`}
            data={props.data}
            headers={headersQuat}
          >
            <Button variant='contained' color='success' sx={{ my: "1em" }}>
              Download Data
            </Button>
          </CSVLink>
        );
      case "AmbientLightSensor":
        return (
          <CSVLink
            filename={`${name}_${props.jobId}`}
            data={props.data}
            headers={headersLight}
          >
            <Button variant='contained' color='success' sx={{ my: "1em" }}>
              Download Data
            </Button>
          </CSVLink>
        );
      default:
        return;
    }
  };

  useEffect(() => {
    setSensorReqs(props.sensors.find((el) => el.name == props.name));
  }, [props.sensors]);
  return (
    <Paper>
      <Container sx={{ textAlign: "center", mt: "0.5em" }}>
        <Typography sx={{ fontWeight: "bold", my: "0.3em", fontSize: 20 }}>
          {props.name.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
        </Typography>
        {sensorReqs && (
          <Typography sx={{ fontWeight: "bold", my: "0.3em", fontSize: 20 }}>
            {props.data.length} / {sensorReqs.maxReadings}
          </Typography>
        )}
        <DataComponent {...props} />
        <Container sx={{ mt: "0.5em" }}>
          {props.data.map((d, i) => {
            return (
              <>
                <Box sx={{ textAlign: "left", my: "0.3em" }} key={i}>
                  <Typography>
                    Timestamp: {new Date(d.timestamp).toLocaleString()}
                  </Typography>
                  {props.name != "Video" &&
                  props.name != "Audio" &&
                  props.name != "Photo" ? (
                    Object.keys(d.payload).map((v, i) => {
                      if (v == "quaternion") {
                        return (
                          <>
                            {" "}
                            Quaternion:
                            <br />
                            {d.payload[v].map((vq, i) => {
                              return (
                                <>
                                  <Typography variant='caption'>
                                    {vq}
                                  </Typography>
                                  <br />
                                </>
                              );
                            })}
                          </>
                        );
                      } else {
                        return (
                          <Typography sx={{ p: 0, m: 0 }} key={i}>
                            {v}: {d.payload[v]}
                          </Typography>
                        );
                      }
                    })
                  ) : (
                    <MediaDialogLocal data={d} name={props.name} />
                  )}
                </Box>
                <Divider />
              </>
            );
          })}
          {}
          {getDownload(props.name)}
        </Container>
      </Container>
    </Paper>
  );
};

const MediaDialogLocal = ({ data, name }) => {
  const [open, setOpen] = useState(false);

  const getMediaType = () => {
    // console.log(name);
    switch (name) {
      case "Video":
        return (
          <video
            style={{ borderRadius: "0.5em" }}
            width={"100%"}
            src={
              process.env.REACT_APP_BASE_URL +
              `/crowdsourcer/get${name}?id=${data.payload}`
            }
            controls
          />
        );

      case "Audio":
        return (
          <audio
            style={{ borderRadius: "0.5em" }}
            width={"100%"}
            src={
              process.env.REACT_APP_WORKER_URL +
              `/sensorData/get${name}?id=${data.payload}`
            }
            controls
          />
        );

      case "Photo":
        return (
          <img
            style={{ borderRadius: "0.5em" }}
            width={"100%"}
            src={
              process.env.REACT_APP_WORKER_URL +
              `/sensorData/get${name}?id=${data.payload}`
            }
          />
        );

      default:
        // console.log("d");
        break;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          {getMediaType()}
          <Typography>{new Date(data.timestamp).toLocaleString()}</Typography>
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant='contained'
      >
        show
      </Button>
    </>
  );
};

const RateDialog = ({ job, open, setOpen }) => {
  const { getJobs } = useContext(CampaignContext);
  const [rating, setRating] = useState("OK");
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleRate = async () => {
    // console.log(rating);
    if (!feedback) {
      enqueueSnackbar("Missing feedback", { variant: "error" });
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "/crowdsourcer/rateJob",
        {
          jobId: job._id,
          slotId: job.slotIDMW,
          rating: rating,
          feedback: feedback,
        }
      );
      await getJobs();
      enqueueSnackbar("Job rated successfully!", { variant: "success" });
      setOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to rate job", { variant: "error" });
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogContent>
        <Typography>Rating</Typography>
        <Select
          onChange={(e) => {
            setRating(e.target.value);
          }}
          value={rating}
        >
          <MenuItem value={"OK"}>OK</MenuItem>
          <MenuItem value={"NOK"}>Not OK</MenuItem>
        </Select>
        <Typography sx={{ mt: "1em" }}>Feedback</Typography>
        <TextField
          value={feedback}
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
          multiline
          rows={5}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => {
            handleRate();
          }}
          fullWidth
          variant='contained'
        >
          rate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Data;
