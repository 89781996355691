import React from "react";
import { Container, Card, CardContent } from "@mui/material";

function JobTimeExpired() {
  return (
    <Container
      sx={{
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Card>
        <CardContent sx={{ textAlign: "center" }}>
          The Time To Complete this job has Expired.
        </CardContent>
      </Card>
    </Container>
  );
}

export default JobTimeExpired;
