import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const textfieldTheme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        required: true,
        sx: { my: "1em", width: "75%" },
      },
    },
  },
});

export function LoginTextfieldThemeProvider({ children }) {
  return <ThemeProvider theme={textfieldTheme}>{children}</ThemeProvider>;
}

export const sensorCheckCardTheme = createTheme({
  components: {
    MuiCard: {
      defaultProps: {
        sx: { my: "0.3em", py: "0.5em" },
      },
    },
  },
});
