import React from "react";
import { Card, Typography } from "@mui/material";

function Disclaimer() {
  return (
    <Card sx={{ m: "1em", py: "3em", px: "4em" }}>
      Disclaimer: By uploading any content, including sensor readings, images,
      videos, and audio recordings, to the Sensorworker platform, you agree to
      transfer the copyright and all associated intellectual property rights to
      the platform. Please read the following terms and conditions carefully
      before using the Sensorworker platform. Your use of the platform signifies
      your acceptance and agreement to be bound by these terms. If you do not
      agree with any of these terms, please refrain from using the platform.
      <ol>
        <li>
          Ownership and Copyright Transfer: By uploading content to the
          Sensorworker platform, you acknowledge and agree that you are the
          rightful owner of the content or have obtained the necessary
          permissions and rights to transfer the copyright to the platform. You
          hereby grant Sensorworker a perpetual, irrevocable, worldwide,
          royalty-free, and non-exclusive license to use, reproduce, modify,
          adapt, distribute, and publicly display the uploaded content for any
          purpose deemed necessary by Sensorworker.
        </li>
        <li>
          Responsibility for Uploaded Content: You are solely responsible for
          the content you upload to the Sensorworker platform. You affirm that
          the content does not infringe upon any copyrights, trademarks, or any
          other intellectual property rights of third parties. Sensorworker does
          not claim ownership over the content until explicitly stated in this
          disclaimer.
        </li>
        <li>
          User Obligations: You agree not to upload or share any content that is
          unlawful, defamatory, obscene, harmful, or infringing upon any
          third-party rights. Furthermore, you agree not to upload any content
          that contains viruses, malware, or any other harmful elements that
          could disrupt or damage the platform or other users' devices.
        </li>
        <li>
          Indemnification: You agree to indemnify and hold Sensorworker, its
          affiliates, employees, and partners harmless from any claims, damages,
          liabilities, and expenses arising from your use of the platform,
          including but not limited to the content you upload and any violation
          of intellectual property rights.
        </li>
        <li>
          Modifications to the Disclaimer: Sensorworker reserves the right to
          modify or amend this disclaimer at any time without prior notice. It
          is your responsibility to review the disclaimer periodically for any
          changes. Continued use of the platform after modifications implies
          your acceptance of the revised terms.
        </li>
      </ol>
      Please note that this disclaimer is a legal agreement between you (the
      user) and Sensorworker (the platform provider) governing the use of the
      platform and the content you upload. If you do not agree with any part of
      this disclaimer, we kindly request that you refrain from using the
      Sensorworker platform.
      <Typography sx={{ mt: "0.5em" }}>
        You can contact us at
        <Typography variant='button'>
          {"  "}
          help.sensorworker@gmail.com{"  "}
        </Typography>
        for any enquire.
      </Typography>
    </Card>
  );
}

export default Disclaimer;
