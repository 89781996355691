import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Box,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import * as L from "leaflet";
import axios from "axios";

function DataView() {
  const [allData, setAllData] = useState([]);
  const [openMedia, setOpenMedia] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_WORKER_URL + "/sensorData/allData")
      .then((res) => {
        // console.log(res.data);
        setAllData(res.data);
      })
      .then((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <Container sx={{ textAlign: "center" }}>
        {allData.length > 0 &&
          allData.map((data, i) => {
            // console.log("running");
            return (
              <Card key={i} sx={{ my: "0.3em" }}>
                <CardContent>
                  <DataComponent
                    {...data}
                    setOpenMedia={setOpenMedia}
                    setId={setId}
                  />
                </CardContent>
              </Card>
            );
          })}
      </Container>
    </>
  );
}

export const DataComponent = (props) => {
  const { name, data, jobId, setOpenMedia, setId } = props;
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // console.log([data[0].location.lat, data[0].location.lng]);
    setLocations(data.map((d) => d.location));
  }, []);

  return (
    <Box>
      {data.length > 0 && locations.length > 0 && (
        <MapContainer
          style={{ height: "30em" }}
          // center={[data[0].location.lat, data[0].location.lng]}
          zoom={20}
          center={data[0].location}
        >
          <Polyline positions={locations} />
          {data.map((d, i) => {
            if (["Audio", "Video", "Photo"].indexOf(name) > -1) {
              return <MediaMarker data={d} name={name} />;
            } else {
              return (
                <Marker key={i} position={d.location}>
                  <Popup>
                    <Box>
                      {Object.keys(d.payload).map((v, i) => {
                        if (v == "quaternion") {
                          return (
                            <>
                              {" "}
                              Quaternion:
                              <br />
                              {d.payload[v].map((vq, i) => {
                                return (
                                  <>
                                    <Typography variant='caption'>
                                      {vq}
                                    </Typography>
                                    <br />
                                  </>
                                );
                              })}
                            </>
                          );
                        } else {
                          return (
                            <Typography sx={{ p: 0, m: 0 }} key={i}>
                              {v}: {d.payload[v]}
                            </Typography>
                          );
                        }
                      })}
                      <Typography>
                        {new Date(d.timestamp).toLocaleString()}
                      </Typography>
                    </Box>
                  </Popup>
                </Marker>
              );
            }
          })}
          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        </MapContainer>
      )}
    </Box>
  );
};

export const MediaMarker = ({ data, name }) => {
  const [open, setOpen] = useState(false);

  const getMediaType = () => {
    switch (name) {
      case "Video":
        return (
          <video
            style={{ borderRadius: "0.5em" }}
            width={"100%"}
            src={
              process.env.REACT_APP_BASE_URL +
              `/crowdsourcer/get${name}?id=${data.payload}`
            }
            controls
          />
        );

      case "Audio":
        return (
          <audio
            style={{ borderRadius: "0.5em" }}
            width={"100%"}
            src={
              process.env.REACT_APP_WORKER_URL +
              `/sensorData/get${name}?id=${data.payload}`
            }
            controls
          />
        );

      case "Photo":
        return (
          <img
            style={{ borderRadius: "0.5em" }}
            width={"100%"}
            src={
              process.env.REACT_APP_WORKER_URL +
              `/sensorData/get${name}?id=${data.payload}`
            }
          />
        );

      default:
        // console.log("d");
        break;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          {getMediaType()}
          <Typography>{new Date(data.timestamp).toLocaleString()}</Typography>
        </DialogContent>
      </Dialog>
      <Marker
        eventHandlers={{
          click: () => {
            setOpen(true);
          },
        }}
        position={data.location}
      />
    </>
  );
};

export default DataView;
