import React from "react";
import { Container, Paper } from "@mui/material";

function CentralCard({ children, maxWidth = "md", component = "div" }) {
  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "1em",
      }}
    >
      <Paper
        component={component}
        elevation={2}
        sx={{
          width: "100%",
          padding: "2em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Paper>
    </Container>
  );
}

export default CentralCard;
