import React, { useEffect, useState } from "react";
import * as rdd from "react-device-detect";
import {
  Button,
  Container,
  Card,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import {
  DeviceErrorDialog,
  ExpSensorGuideDialog,
  GpsGuideDialog,
  AudioVideoGuideDialog,
} from "../../components/Dialogs";
import { sensorCheckCardTheme } from "../../utils/themes";
import {
  checkRegularSensors,
  checkGps,
  checkAudioVideo,
  checkNetwork,
} from "../../utils/sensorCheckFunctions";

function SensorCheck() {
  // state for os and browser
  const [correctDevice, setCorrectDevice] = useState(false);

  // state for regular sensors
  const [genericSensorEnabled, setGenericSensorEnabled] = useState(false);

  // state for experimental sensors
  const [experimentalSensorsEnabled, setExperimentalSensorsEnabled] =
    useState(false);
  const [expSensorGuideShow, setExpSensorGuideShow] = useState(false);

  // state for gps
  const [gpsEnabled, setGpsEnabled] = useState(null);
  const [showGpsEnableMessage, setShowGpsEnableMessage] = useState(true);
  const [gpsErrorMesssage, setGpsErrorMessage] = useState("Checking...");
  const [gpsGuideShow, setGpsGuideShow] = useState(false);

  // state for camera and microphone
  const [audioVideoEnabled, setAudioVideoEnabled] = useState(null);
  const [showAudioVideoEnableMessage, setShowAudioVideoEnableMessage] =
    useState(true);
  const [audioVideoErrorMesssage, setAudioVideoErrorMessage] =
    useState("Checking...");
  const [audioVideoGuideShow, setAudioVideoGuideShow] = useState(false);

  // state for network
  const [netEnabled, setNetEnabled] = useState(null);
  const [showNetErrorMessage, setShowNetErrorMessage] = useState(true);
  const [netErrorMesssage, setNetErrorMessage] = useState("Checking...");

  // every check in useEffect is run immediately when the page is loaded
  useEffect(() => {
    if (rdd.isAndroid && rdd.isChrome) {
      setCorrectDevice(true);
    } else {
      setCorrectDevice(false);
    }

    checkRegularSensors(
      { permissionName: "gyroscope", name: "Gyroscope" },
      { permissionName: "accelerometer", name: "Accelerometer" },
      setGenericSensorEnabled
    );
    checkRegularSensors(
      {
        permissionName: "ambient-light-sensor",
        name: "AmbientLightSensor",
      },
      { permissionName: "magnetometer", name: "Magnetometer" },
      setExperimentalSensorsEnabled
    );

    checkGps(setGpsEnabled, setShowGpsEnableMessage, setGpsErrorMessage);
    checkAudioVideo(
      setAudioVideoEnabled,
      setShowAudioVideoEnableMessage,
      setAudioVideoErrorMessage
    );
    checkNetwork(setNetEnabled, setShowNetErrorMessage, setNetErrorMessage);
  }, []);

  return (
    <Container sx={{ py: "1rem", display: "flex", flexDirection: "column" }}>
      {/* DIALOG COMPONENTS */}
      <DeviceErrorDialog open={!correctDevice} />
      <ExpSensorGuideDialog
        open={expSensorGuideShow}
        onClose={() => {
          setExpSensorGuideShow(false);
        }}
      />
      <GpsGuideDialog
        open={gpsGuideShow}
        onClose={() => {
          setGpsGuideShow(false);
        }}
      />
      <AudioVideoGuideDialog
        open={audioVideoGuideShow}
        onClose={() => {
          setAudioVideoGuideShow(false);
        }}
      />

      <ThemeProvider theme={sensorCheckCardTheme}>
        <SensorCheckCard
          title='Android OS & Chrome Browser'
          enable={correctDevice}
        />

        <SensorCheckCard
          title='Generic Sensors'
          enable={genericSensorEnabled}
        />

        <SensorCheckCard
          title='Experimental Sensors'
          enable={experimentalSensorsEnabled}
        >
          {experimentalSensorsEnabled ? null : (
            <Container>
              <Button
                sx={{ p: 0 }}
                onClick={() => {
                  setExpSensorGuideShow(true);
                }}
              >
                How do i enable it?
              </Button>
            </Container>
          )}
        </SensorCheckCard>

        <SensorCheckCard title='GPS' enable={gpsEnabled}>
          {showGpsEnableMessage && (
            <>
              <Container>
                <Typography variant='caption'>{gpsErrorMesssage}</Typography>
              </Container>
              <Container>
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setGpsGuideShow(true);
                  }}
                >
                  How do i enable it?
                </Button>
              </Container>
            </>
          )}
        </SensorCheckCard>

        <SensorCheckCard title='Camera & Microphone' enable={audioVideoEnabled}>
          {showAudioVideoEnableMessage && (
            <>
              <Container>
                <Typography variant='caption'>
                  {audioVideoErrorMesssage}
                </Typography>
              </Container>
              <Container>
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setAudioVideoGuideShow(true);
                  }}
                >
                  How do i enable it?
                </Button>
              </Container>
            </>
          )}
        </SensorCheckCard>
        <SensorCheckCard title='Network Sensor' enable={netEnabled}>
          {showNetErrorMessage && (
            <Container>
              <Typography variant='caption'>{netErrorMesssage}</Typography>
            </Container>
          )}
        </SensorCheckCard>
      </ThemeProvider>
    </Container>
  );
}

const SensorCheckCard = ({ title, enable, children }) => {
  return (
    <Card>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{title}</Typography>
        {enable ? (
          <CheckCircle fontSize='large' color='success' />
        ) : (
          <Cancel fontSize='large' color='error' />
        )}
      </Container>
      {children}
    </Card>
  );
};

export default SensorCheck;
