import { useEffect, useReducer, useRef } from "react";
import * as dfns from "date-fns";

const ABSOLUTE_ORIENTATION = "AbsoluteOrientationSensor";
const ACCELEROMETER = "Accelerometer";
const AMBIENT_LIGHT = "AmbientLightSensor";
const GRAVITY = "GravitySensor";
const GYROSCOPE = "Gyroscope";
const LINEAR_ACCELERATION = "LinearAccelerationSensor";
const MAGNETOMETER = "Magnetometer";
const RELATIVE_ORIENTATION = "RelativeOrientationSensor";

// reducer for sensor coordinates
function sensor3dreducer(state, action) {
  return {
    x: action.ref.x,
    y: action.ref.y,
    z: action.ref.z,
    // timestamp: action.ref.timestamp,
  };
}

const sensor3dInitState = {
  x: 0,
  y: 0,
  z: 0,
  // timestamp: 0,
};

function lightReducer(state, action) {
  return {
    lux: action.ref.illuminance,
    // timestamp: action.ref.timestamp,
  };
}

const lightSensorInitState = {
  lux: 0,
  // timestamp: 0,
};

function quaternionReducer(state, action) {
  return {
    quaternion: action.ref.quaternion,
    // timestamp: action.ref.timestamp,
  };
}

const quaternionInitState = {
  quaternion: [0, 0, 0, 0],
  // timestamp: 0,
};

const useGenericSensor = ({
  sensorType,
  initialState,
  reducer,
  onActivate,
  onReading,
  onError,
  frequency,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const sensor = useRef(null);

  // check if sensor is already created
  if (sensor.current === null && window[sensorType] != null) {
    // console.log(window[sensorType] != null);
    sensor.current = new window[sensorType]({ frequency: frequency });
  }

  // set event listeners for sensor
  useEffect(() => {
    if (sensor.current === null) {
      return;
    }

    sensor.current.onreading = (event) => {
      // update state on sensor reading
      dispatch({ ref: sensor.current });
      if (onReading) {
        onReading(sensor.current, event);
      }
    };

    sensor.current.onactivate = (event) => {
      if (onActivate) {
        onActivate(sensor.current, event);
      }
    };

    sensor.current.onerror = (event) => {
      // dispatch({ ref: sensor.current });
      if (onError) {
        onError(sensor.current, event);
      }
    };
  }, []);

  const getReading = () => {
    sensor.current.start();
  };

  return { data: state, controller: sensor.current };
};

// generic 3D sensor hook
const use3DSensor = (props) => {
  return useGenericSensor({
    initialState: sensor3dInitState,
    reducer: sensor3dreducer,
    ...props,
  });

  // return {
  //   initialState: sensor3dInitState,
  //   reducer: sensor3dreducer,
  //   ...props,
  // };
};

// generic quaternion sensor hook
const useQuaternionSensor = (props) => {
  return useGenericSensor({
    initialState: quaternionInitState,
    reducer: quaternionReducer,
    ...props,
  });
};

/**
 * EXPORTED SENSORS
 */

//light sensor hook
export const useLightSensor = (props) => {
  return useGenericSensor({
    sensorType: AMBIENT_LIGHT,
    initialState: lightSensorInitState,
    reducer: lightReducer,
    ...props,
  });
};

// relative orientation sensor hook
export const useRelativeOrientation = (props) => {
  return useQuaternionSensor({
    sensorType: RELATIVE_ORIENTATION,
    ...props,
  });
};

// absolute orientation sensor hook
export const useAbsoluteOrientation = (props) => {
  return useQuaternionSensor({
    sensorType: ABSOLUTE_ORIENTATION,
    ...props,
  });
};

// accelerometer hook
export const useAccelerometer = (props) => {
  return use3DSensor({ sensorType: ACCELEROMETER, ...props });
  // return { sensorType: ACCELEROMETER, ...props };
};

// gravity sensor hook
export const useGravity = (props) => {
  return use3DSensor({ sensorType: GRAVITY, ...props });
};

// gyroscope hook
export const useGyroscope = (props) => {
  return use3DSensor({ sensorType: GYROSCOPE, ...props });
};

// magnetometer hook
export const useMagnetometer = (props) => {
  return use3DSensor({ sensorType: MAGNETOMETER, ...props });
};

// linear acceleration sensor hook
export const useLinearAcceleration = (props) => {
  return use3DSensor({ sensorType: LINEAR_ACCELERATION, ...props });
};
