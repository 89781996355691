import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import CentralCard from "../components/CentralCard";
import { Typography, TextField, InputAdornment } from "@mui/material";
import { LoginTextfieldThemeProvider } from "../utils/themes";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const routerNavigate = useNavigate();

  useEffect(() => {
    const checkLogin = async () => {
      try {
        await axios.get(
          process.env.REACT_APP_BASE_URL + "/register/checkLogin"
        );
        routerNavigate("/crowdsourcer", { replace: true });
      } catch (err) {}
    };

    checkLogin();
  }, []);

  const updateData = (e, fn) => {
    fn(e.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { username, password };

    await axios
      .post(process.env.REACT_APP_BASE_URL + "/register/login", data)
      .then((res) => {
        // console.log(res);
        enqueueSnackbar(res.data, { variant: "success" });
        routerNavigate("/crowdsourcer");
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while Logging in: ${err.response.data.message}`,
          {
            variant: "error",
          }
        );
      });
    /* if (!bcrypt.compareSync(password,'$2a$10$4G4r3zslO2IUgijzZ0Kwf.j6PBWWXzjToBF5cOrQsebEfoHaDNCkO')){
      enqueueSnackbar(
        `Error while Logging in: Wrong password`,
        {
          variant: "error",
          autodismiss: false,
          onClick: (event, snackbarKey) => {
            // Dismiss the snackbar when clicked
            console.log("closing");
          }
        }

        
      );
    }else{
      await axios
      .post(process.env.REACT_APP_BASE_URL + "/register/login", data)
      .then((res) => {
        console.log(res);
        enqueueSnackbar(res.data, { variant: "success" });
        routerNavigate("/crowdsourcer");
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while Logging in: ${err.response.data.message}`,
          {
            variant: "error",
          }
        );
      });
    } */
    
    setLoading(false);
  };

  return (
    <CentralCard maxWidth={"md"} component={"form"}>
      <Typography variant='h5'>Login</Typography>
      <LoginTextfieldThemeProvider>
        <TextField
          onChange={(e) => {
            updateData(e, setUsername);
          }}
          id='username'
          label='Username'
          value={username}
        ></TextField>
        <TextField
          onChange={(e) => {
            updateData(e, setPassword);
          }}
          id='password'
          label='Password'
          type={showPassword ? "text" : "password"}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                position='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>
            ),
          }}
        ></TextField>
      </LoginTextfieldThemeProvider>
      <LoadingButton
        loading={loading}
        type='submit'
        variant='contained'
        size='large'
        onClick={submit}
      >
        login
      </LoadingButton>
      <Typography sx={{ my: "1em" }}>
        Don't have an account?{" "}
        <Link to='/register' replace={true}>
          Register
        </Link>
      </Typography>
    </CentralCard>
  );
}

export default Login;
