export function getFloatFixedValue(value, fractionDigits) {
  var parseFloatValue = parseFloat(value);
  if (fractionDigits !== undefined) {
    return parseFloatValue.toFixed(fractionDigits);
  }
  return parseFloatValue;
}

export const sensorNameArray = [
  "Accelerometer",
  "Gyroscope",
  "Magnetometer",
  "AmbientLightSensor",
  "GravitySensor",
  "LinearAccelerationSensor",
  "RelativeOrientationSensor",
  "AbsoluteOrientationSensor",
  "Photo",
  "Audio",
  "Video",
];
