import React, { useState, useEffect } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
  CardActions,
  Backdrop,
  CircularProgress,
  Link as MuiLink,
  Stepper,
  Step,
  StepLabel,
  Divider,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FlagIcon from "@mui/icons-material/Flag";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";

import { useSnackbar } from "notistack";

import {
  MapContainer,
  TileLayer,
  useMap,
  Polygon,
} from "react-leaflet";
import * as L from "leaflet";
import * as dfns from "date-fns";

function CampaignSelect() {
  const [params, setParams] = useSearchParams();
  const [ipPosShowError, setIpPosShowError] = useState(false);
  const [ipPosition, setIpPosition] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [isPreview, setIsPreview] = React.useState(true);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const routerNavigate = useNavigate();

  const [workerId, setWorkerId] = useState(params.get("workerId"));
  const [campaignId, setCampaignId] = useState(params.get("campaignId"));
  const [slotId, setSlotId] = useState(params.get("slotId"));
  const [logId, setLogId] = useState();

  useEffect(() => {
    // console.log("SLOTID ID: " + params.get("slotId"));
    if (
      params.get("workerId") !== "DummyWorkerId" &&
      params.get("slotId") !== "DummySlotId"
    ) {
      setIsPreview(false);
      searchJob();
    } else {
      setIsPreview(true);
      sendLog();
      // setLoading(false);
    }
  }, []);

  const searchJob = async () => {
    axios
      .get(process.env.REACT_APP_WORKER_URL + "/campaign/getWorkerActiveJob", {
        params: {
          workerId: params.get("workerId"),
          campaignIDMW: params.get("campaignId"),
        },
      })
      .then(async (res) => {
        // console.log(res);
        if (res.data !== "OK") {
          goToQRPage(
            params.get("campaignId"),
            res.data.slotIDMW,
            res.data.workerIDMW
          );
        } else {
          // sendLog();
          // console.log("SENDING");
          await createJob();
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error checking for active jobs", { variant: "error" });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendLog = async () => {
    axios
      .get("https://api.ipgeolocation.io/ipgeo?apiKey=" + process.env.REACT_APP_IP_GEOLOCATION_KEY, { withCredentials: false })
      .then(async (res) => {
        try {
          const res2 = await axios.post(
            process.env.REACT_APP_WORKER_URL + "/log/postCampaignListPost",
            {
              ipAddress: res.data.ip,
              ipApiResponse: JSON.stringify(res.data),
              workerId,
              campaignId,
              slotId,
            }
          );
          // console.log(res2.data);
          setLogId(res2.data.log._id);
        } catch (err) {
          enqueueSnackbar("Failed to create interaction log", {
            variant: "error",
          });

          console.error(err);
        }
      })
      .catch((err) => {
        console.error(err);
        setIpPosShowError(true);
      });
  };

  const goToQRPage = (campaingId, slotId, workerId) => {
    routerNavigate(
      `/sensorCollectQR?campaignId=${campaingId}&slotId=${slotId}&workerId=${workerId}`,
      { replace: "true" }
    );
  };
  useEffect(() => {
    getIpLocation();
  }, []);

  const getIpLocation = () => {
    axios
      .get("https://api.ipgeolocation.io/ipgeo?apiKey=" + process.env.REACT_APP_IP_GEOLOCATION_KEY, { withCredentials: false })
      .then(async (res) => {
        setIpPosition([res.data.latitude, res.data.longitude]);
        // console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
        setIpPosShowError(true);
      });
  };

  useEffect(() => {
    getCampaigns();
  }, [ipPosition]);

  const getCampaigns = () => {
    if (!ipPosition) {
      return;
    }

    axios
      .get(process.env.REACT_APP_WORKER_URL + "/campaign/getCampaigns", {
        params: {
          campaignIDMW: params.get("campaignId"),
          lat: ipPosition[0],
          lng: ipPosition[1],
          // lat: 0,
          // lng: 0,
          // coords: ipPosition,
        },
      })
      .then((res) => {
        setCampaigns(res.data);
      })
      .catch((err) => {
        enqueueSnackbar("Unable to get campaings", { variant: "error" });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showCampaigns();
  }, [campaigns]);

  const showCampaigns = () => {
    if (!campaigns.length) {
      return;
    }
  };

  const createJob = async () => {
    axios
      .post(
        process.env.REACT_APP_WORKER_URL + "/campaign/checkExistanceAndCreate",
        {
          slotId: params.get("slotId"),
          workerId: params.get("workerId"),
          campaignId: null,
          campaignIDMW: params.get("campaignId"),
          logId: logId,
        }
      )
      .then(async (res) => {
        // console.log(res.data);
        enqueueSnackbar("Successfully started the task!", {
          variant: "success",
        });
        // goToQRPage(
        //   params.get("campaignId"),
        //   params.get("slotId"),
        //   params.get("workerId")
        // );
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while starting task (${err.response.data.message})`,
          {
            variant: "error",
          }
        );
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTakePart = async (internalCampaignId) => {
    axios
      .post(process.env.REACT_APP_WORKER_URL + "/campaign/setCampaignSW", {
        slotId: params.get("slotId"),
        workerId: params.get("workerId"),
        campaignId: internalCampaignId,
        campaignIDMW: params.get("campaignId"),
        logId: logId,
      })
      .then(async (res) => {
        // console.log(res.data);
        enqueueSnackbar("Campaign selected!", {
          variant: "success",
        });
        goToQRPage(
          params.get("campaignId"),
          params.get("slotId"),
          params.get("workerId")
        );
      })
      .catch((err) => {
        enqueueSnackbar(
          `Error while setting campaign (${err.response.data.message})`,
          {
            variant: "error",
          }
        );
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <></>
      )}
      <IpApiDialog open={ipPosShowError} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            m: "0.5em",
            display: "flex",
            flexDirection: "column",
            // height: "35em",
          }}
        >
          <Typography variant='h4' sx={{ my: "0.5em", textAlign: "center" }}>
            Welcome to SensorWorker!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "xl",
              px: "2em",
              pb: "2em",
            }}
          >
            <Box sx={{ width: "50%", pt: "1em" }}>
              <Typography textAlign={"center"} fontSize={19}>
                Sensorworker is a{" "}
                <MuiLink
                  target='_blank'
                  href='https://en.wikipedia.org/wiki/Crowdsensing'
                >
                  crowdsensing
                </MuiLink>{" "}
                platform, where we aim to collect data from your mobile phone's sensors.
                Below is a list of Sensorworker campaigns you
                can choose from, each specific for you geographic region and with a list of
                sensors needed to complete the task for that particular campaign.
                Once the task is completed, it will be evaluated, and you will be rewarded
                in your Microworkers account as usual, but you can continue to participate
                in this Microworkers campaign to complete all other Sensorworker campaigns!
              </Typography>
            </Box>
            <Divider orientation='vertical' flexItem sx={{ mx: "0.5em" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "50%",
                pt: "1em",
              }}
            >
              <Typography textAlign={"center"} fontSize={19} sx={{ mb: "1em" }}>
                To check which sensors are available on your mobile device,
                you can scan the following QR code. Alternatively, if you are already
                using a mobile device, you can click
                <Link to={"/sensorCheck"} target='_blank'>
                  {" "}
                  here{" "}
                </Link>
              </Typography>

              <Container
                sx={{
                  p: "1em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#abbbd3",
                  width: "13em",
                  height: "13em",
                  borderRadius: "20%",
                }}
              >
                <QRCodeSVG
                  size={160}
                  bgColor='#abbbd3'
                  fgColor='black'
                  value={process.env.REACT_APP_URL + "/sensorCheck"}
                />
              </Container>
              <Typography
                variant='caption'
                sx={{ mt: "1em", textAlign: "center" }}
              >
                By taking part in any campaign, you agree to the following{" "}
                <Link to={"/disclaimer"} target='_blank'>
                  Terms & Conditions
                </Link>
              </Typography>
              <Typography
                sx={{ mt: "0.5em", textAlign: "center", fontSize: 19 }}
              >
                You can contact us at
                <Typography variant='button'>
                  {"  "}
                  help.sensorworker@gmail.com{"  "}
                </Typography>
                for any enquire.
              </Typography>
            </Box>
          </Box>

          <Typography
            variant='h5'
            sx={{ my: "0.5em", textAlign: "center", color: "red" }}
          >

            Take part in a campaign below to start your task!
          </Typography>
          {/* <Typography>
            {params.get("workerId") === "DummyWorkerId" ? (
              <Link
                to={`/sensorCollectQR?campaignId=${params.get(
                  "campaignId"
                )}&slotId=${params.get("slotId")}&workerId=DummyWorkerId`}
              >
                Have you already started a task?
              </Link>
            ) : (
              <></>
            )}
          </Typography> */}
        </Card>
      </Box>
      {campaigns.length > 0 ? (
        <Container>
          {campaigns.map((value, index) => {
            const bounds = L.polygon(value.geojson.coordinates).getBounds();
            return (
              <Card key={index} sx={{ maxWidth: "lg", my: "0.3em" }}>
                <CardContent>
                  <MapContainer
                    style={{ height: "30em" }}
                    center={bounds.getCenter()}
                  >
                    <Polygon positions={value.geojson.coordinates} />
                    <BoundsFitting bounds={bounds} />
                    <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                  </MapContainer>

                  <Typography
                    sx={{
                      fontSize: "1.2em",
                      mt: "0.8em",
                    }}
                  >
                    Campaign Title: <b>{value.title}</b>
                  </Typography>
                  <Typography sx={{ mb: "0.3em" }}>
                    Campaign Description: {value.description}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8em" }}>
                    <Typography
                      component={"span"}
                      sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                    >
                      sensors:{" "}
                    </Typography>
                    {value.sensors.map((sensorValue, sensorIndex) => {
                      return <span key={sensorIndex}>{sensorValue.name} </span>;
                    })}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8em" }}>
                    <Typography
                      component={"span"}
                      sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                    >
                      Campaign Ends:{" "}
                    </Typography>
                    {new Date(value.end).toLocaleString()}
                  </Typography>
                  {/* <Typography sx={{ fontSize: "0.8em" }}>
                    <Typography
                      component={"span"}
                      sx={{ fontWeight: "bold", fontSize: "0.9em" }}
                    >
                      Time To Complete:{" "}
                    </Typography>
                    {Object.entries(
                      dfns.intervalToDuration({
                        start: new Date(0),
                        end: new Date(value.timeToComplete),
                      })
                    ).map((val, i) => {
                      if (val[1] > 0) {
                        return (
                          <>
                            {val[1]} {val[0]}{" "}
                          </>
                        );
                      }
                    })}
                  </Typography> */}
                </CardContent>
                <CardActions sx={{ display: "flex" }}>
                  <Button
                    onClick={function() {
                      setLoading(true);
                      handleTakePart(value.id);
                    }}
                    variant='contained'
                    sx={{ width: "100%" }}
                    disabled={isPreview}
                  >
                    Take Part
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </Container>
      ) : (
        <>
          {!loading && (
            <Box sx={{ textAlign: "center", my: "0.5em" }}>
              <Typography variant='h3' sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
                There are no campaigns in your location!
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}

const IpApiDialog = ({ open }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography>
          Unable to find you position throught you network. You may consider
          turning off your adblock and reload the page.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export const BoundsFitting = ({ bounds }) => {
  const map = useMap();
  map.fitBounds(bounds);
  return <></>;
};

const IntroStep = () => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            m: "0.5em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            maxWidth: "lg",
            height: "25vw",
          }}
        >
          <Typography variant='h5' sx={{ py: "0.5em" }}>
            Welcome to SensorWorker!
          </Typography>
          <Typography>
            Sensorworker is{" "}
            <MuiLink
              href='https://en.wikipedia.org/wiki/Crowdsensing'
              target='_blank'
              rel='noreferrer'
            >
              Mobile Crowdsensing
            </MuiLink>{" "}
            platform completely integrated with Microworkers.
          </Typography>
          <Typography textAlign='center'>
            SensorWorker aims at collecting data from the sensors in your mobile
            device straight from the browser, without requiring you to install
            any software!
            <br />
            the only requirement is that you use a browser compatible with the
            sensor readings we need.
          </Typography>
        </Card>
      </Box>
    </>
  );
};

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

export const IntroStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Card sx={{ m: "0.5em", p: "1em" }}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key='fanuclo'>
            <StepLabel
              StepIconComponent={CelebrationIcon}
              StepIconProps={{
                sx: {
                  p: "0.15em",
                  fontSize: 30,
                  backgroundColor: "#5572a1",
                  borderRadius: "100%",
                  color: "white",
                },
              }}
            >
              Welcome!
            </StepLabel>
          </Step>
          <Step key='fanuclo'>
            <StepLabel
              StepIconComponent={SettingsIcon}
              StepIconProps={{
                sx: {
                  p: "0.15em",
                  fontSize: 30,
                  backgroundColor: "#5572a1",
                  borderRadius: "100%",
                  color: "white",
                },
              }}
            >
              How it works
            </StepLabel>
          </Step>
          <Step key='fanuclo'>
            <StepLabel
              StepIconComponent={FlagIcon}
              StepIconProps={{
                sx: {
                  p: "0.15em",
                  fontSize: 30,
                  backgroundColor: "#5572a1",
                  borderRadius: "100%",
                  color: "white",
                },
              }}
            >
              Next steps
            </StepLabel>
          </Step>
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color='inherit'
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </Card>
  );
};

export default CampaignSelect;
