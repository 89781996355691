import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";

import { useSnackbar } from "notistack";
import VideoComponent from "./VideoComponent";

export const DeviceErrorDialog = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        Device not supported
      </DialogTitle>
      <DialogContent>
        <Typography align='justify'>
          This device is not supported. Please use an <b>Android device</b> with
          <b> Chrome Browser</b> in order to complete the task. You can scan the
          following QR code with your phone and open the link in Chrome Browser.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export const ExpSensorGuideDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        How to enable experimental sensors
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: "0.5em" }}>
          Follow the steps below to enable experimental sensors on your device.
        </Typography>
        <Typography>1. In a new tab open the following link: </Typography>
        <Typography
          onClick={() => {
            navigator.clipboard
              .writeText("chrome://flags/#enable-generic-sensor-extra-classes")
              .then(() => {
                enqueueSnackbar("Link copied to clipboard", {
                  variant: "info",
                });
              })
              .catch(() => {
                enqueueSnackbar("Failed to copy link to clipboard", {
                  variant: "error",
                });
              });
          }}
          sx={{ m: "0.7em" }}
          fontFamily={"monospace"}
        >
          chrome://flags/#enable-generic-sensor-extra-classes
        </Typography>{" "}
        <Typography>2. Enable the first flag</Typography>
        <Typography>3. Restart Chrome Browser</Typography>
        <Typography>4. Come back to this tab and continue</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const GpsGuideDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        How to allow access to GPS location
      </DialogTitle>
      <DialogContent>
        <Typography>
          Follow the steps below to allow access to GPS location on your device.
        </Typography>
        <Typography>1. Turn on geolocalization on this device </Typography>
        <Typography>
          2. On the top of your screen, press the Lock next to this page's link
        </Typography>
        <Typography>3. Press the Authorization panel</Typography>
        <Typography>4. Turn on GPS access</Typography>
        <Typography>5. Reload this page</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const AudioVideoGuideDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
        How to allow access to Camera and Microphone
      </DialogTitle>
      <DialogContent>
        <Typography>
          Follow the steps below to allow access to Camera and Microphone on
          your device.
        </Typography>
        <Typography>
          1. On the top of your screen, press the Lock next to this page's link
        </Typography>
        <Typography>2. Press the Authorization panel</Typography>
        <Typography>3. Turn on Camera and Microphone access</Typography>
        <Typography>4. Reload this page</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const VideoPhotoDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogContent sx={{ p: "0.3em" }}>
        <VideoComponent {...props} />
      </DialogContent>
    </Dialog>
  );
};
