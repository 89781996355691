export const initCrowdsourcerReducer = {
  name: "",
  surname: "",
  username: "",
  password: "",
  apiKey: "",
  campaignNameMW: "",
  pricePerPosition: 0.05,
};

export function crowdsourcerReducer(state = {}, action) {
  switch (action.type) {
    case "name":
      return { ...state, name: action.name };
    case "surname":
      return { ...state, surname: action.surname };
    case "username":
      return { ...state, username: action.username };
    case "password":
      return { ...state, password: action.password };
    case "apiKey":
      return { ...state, apiKey: action.apiKey };
    case "campaignNameMW":
      return { ...state, campaignNameMW: action.campaignNameMW };
    case "pricePerPosition":
      return { ...state, pricePerPosition: action.pricePerPosition };
    case "all":
      return {
        ...action.data,
      };
    case "reset":
      return {
        ...initCrowdsourcerReducer,
      };
    default:
      return state;
  }
}
